import React from 'react';

const Test = () => {
    return (
        <div >
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
            <h2>okkkkkkkkkkkk</h2>
        </div>
    );
};

export default Test;