import { Tooltip } from 'chart.js';
import React, { useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';


const Graph = () => {

  

 
    return (
        <div> 
            {/* <h1>okkkkkkkk</h1>
            <LineChart width={500} height={400} data={data}>
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            <XAxis dataKey="name" />
          <YAxis />
          <Tooltip></Tooltip>
            </LineChart> */}
          
        </div>
    );
};

export default Graph;