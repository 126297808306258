import React from 'react';

const GiveAds = () => {
    return (
        <div>
            <h2>Give ads</h2>
        </div>
    );
};

export default GiveAds;