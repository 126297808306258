import React from 'react';

const FeaturesSchool = () => {
//   const {sc}= props

    
    return (
        <div>
     

           
           
        </div>
    );
};

export default FeaturesSchool;